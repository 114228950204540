import JSZip from 'jszip'
import { USER_ROLES } from './constants'
import moment from 'moment-timezone'

const paginate = (array, pageSize, pageNumber) => {
  return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
}

const getFileLibraryUrl = (path) => {
  if (!path) return null
  return `${process.env.REACT_APP_LIBRARY_URL}/${path}`
}

const getFileUrl = (path) => {
  if (!path) return null
  return `${process.env.REACT_APP_FILE_URL}/${path}`
}

const openNewTab = (url) => {
  window.open(url, '_blank')
}

const isAdmin = (user) => {
  return [USER_ROLES.admin, USER_ROLES.super_admin].includes(user.role)
}
async function downloadS3Url(path) {
  try {
    // Construct the public URL
    const encodedFileName = encodeURIComponent(path)
    const bucketUrl = process.env.REACT_APP_S3_BUCKET; // Base URL of the public S3 bucket
    const fileUrl = `${bucketUrl}/${encodedFileName}`;

    // Fetch the file and convert to blob
    const response = await fetch(fileUrl);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const blob = await response.blob();
    const fileName = path.split('/').pop();

    // Create a temporary download link
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();

    // Revoke the object URL to release memory
    window.URL.revokeObjectURL(url);
  } catch (error) {
    throw new Error('Unable to download file:', error)
  }
}

async function downloadAnnouncementAttachment(path) {
  try {
    // Construct the public URL
    const encodedFileName = encodeURIComponent(path)
    const bucketUrl = process.env.REACT_APP_S3_BUCKET; // Base URL of the public S3 bucket
    const fileUrl = `${bucketUrl}/${encodedFileName}`;

    // Fetch the file and convert to blob
    const response = await fetch(fileUrl);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const blob = await response.blob();
    const fileName = path.split('/').pop();

    // Create a temporary download link
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();

    // Revoke the object URL to release memory
    window.URL.revokeObjectURL(url);
  } catch (error) {
    throw new Error('Unable to download file:', error)
  }
}

// TODO: AWS related code
function downloadURI(uri, name) {
  fetch(uri).then((response) => {
    response.blob().then((blob) => {
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = name
      a.click()
    })
  })
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const formatCurrency = (value) => {
  if (isNaN(value) === false) {
    if (!value && value !== 0) return '-'
    var formatter = new Intl.NumberFormat('en-CA', {
      style: 'currency',
      currency: 'CAD'
    })

    return formatter.format(value)
  } else {
    return `$${value}`
  }
}

const downloadZip = async (fileUrls, zipName = 'doc.zip') => {
  const zip = new JSZip()

  for (let i = 0; i < fileUrls.length; i++) {
    const fileUrl = fileUrls[i]
    const response = await fetch(fileUrl)
    const fileBlob = await response.blob()
    const fileName = fileUrl?.substring(fileUrl.lastIndexOf('/') + 1)
    zip.file(fileName, fileBlob)
  }

  const content = await zip.generateAsync({ type: 'blob' })
  const url = URL.createObjectURL(content)
  const link = document.createElement('a')
  link.href = url
  link.download = zipName
  link.click()
  URL.revokeObjectURL(url)
}

const getLibraryFileUrl = (path) => {
  if (!path) return null
  return path
}

const downloadLibraryZip = async (fileUrls, zipName = 'doc.zip') => {
  const zip = new JSZip()

  for (let i = 0; i < fileUrls?.length; i++) {
    const fileUrl = fileUrls[i]?.preSignedUrl
    const response = await fetch(fileUrl)
    const fileBlob = await response.blob()
    zip.file(fileUrls[i]?.fileName, fileBlob)
  }

  const content = await zip.generateAsync({ type: 'blob' })
  const url = URL.createObjectURL(content)
  const link = document.createElement('a')
  link.href = url
  link.download = zipName
  link.click()
  URL.revokeObjectURL(url)
}

const bytesToString = (size) => {
  const sizes = [' Bytes', ' KB', ' MB', ' GB', ' TB', ' PB', ' EB', ' ZB', ' YB']

  for (let i = 1; i < sizes.length; i++) {
    if (size < Math.pow(1024, i)) return Math.round((size / Math.pow(1024, i - 1)) * 100) / 100 + sizes[i - 1]
  }
  return size
}

const convertLocalToUTC = (localTime, dateFormat = 'YYYY-MM-DD HH:mm:ss') => {
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const utcTime = moment.tz(localTime, browserTimeZone).utc()
  return localTime ? utcTime : null
}

const convertUTCToLocal = (utcTime, dateFormat = 'YYYY-MM-DD HH:mm:ss') => {
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const localTime = moment.utc(utcTime).tz(browserTimeZone)
  return utcTime ? localTime.format(dateFormat) : null
}


 // Generates a signed URL for a file in the S3 bucket.

const generateSignedUrl = async (key) => {
  const encodedFileName = encodeURIComponent(key)
  const filePath = `${process.env.REACT_APP_S3_BUCKET}/${encodedFileName}`
  return filePath
}

 // Generates a signed URL for a file in the library S3 bucket.

const generateLibrarySignedUrl = async (key) => {
  const encodedFileName = encodeURIComponent(key)
  const filePath = `${process.env.REACT_APP_LIBRARY_BUCKET_URL}/${encodedFileName}`
  return filePath
}


export {
  paginate,
  getFileUrl,
  getFileLibraryUrl,
  formatCurrency,
  capitalizeFirstLetter,
  openNewTab,
  bytesToString,
  downloadZip,
  downloadURI,
  downloadS3Url,
  isAdmin,
  convertLocalToUTC,
  convertUTCToLocal,
  getLibraryFileUrl,
  downloadLibraryZip,
  generateSignedUrl,
  downloadAnnouncementAttachment,
  generateLibrarySignedUrl
}
